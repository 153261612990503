import { Formik, FormikHelpers, useFormikContext } from "formik";
import { produce } from "immer";
import * as Yup from "yup";
import {
  ButtonType,
  CDataButton,
} from "../../../../../../../components/buttons/CDataButton";
import { CDataTypography } from "../../../../../../../components/text/CDataTypography";
import { CDataModalV2 } from "../../../../../../../components/modal/CDataModalV2";
import { DataType } from "../../../../../../../models";
import {
  YupOptionalSchema,
  YupRequiredSchema,
} from "../../../../../../../utility/types/yupHelperTypes";
import { IQueryBuilderColumn } from "../../../models/IQueryBuilderColumn";
import { IQueryBuilderCommonProps } from "../../../models/IQueryBuilderCommonProps";
import {
  IQueryBuilderMetric,
  IQueryBuilderModel,
  QueryAggregateFunctionType,
} from "../../../models/IQueryBuilderModel";
import { getFullyQualifiedColumnName } from "../../../sqlGenerator/getFullyQualifiedColumnName";
import { MetricColumnSelect } from "./MetricsColumnSelect";
import { MetricTypeSelect } from "./MetricTypeSelect";
import { IQueryBuilderTable } from "../../../models/IQueryBuilderTable";
import { CDataAutocomplete } from "@cdatacloud/component-library";
import { QueryBuilderTableDropdownOption } from "../components/QueryBuilderTableDropdownOption";
import { Col, Row } from "reactstrap";

interface AddMetricsModalProps extends IQueryBuilderCommonProps {
  displayed: boolean;
  close: () => void;
}

export function AddMetricsModal(props: AddMetricsModalProps) {
  const { queryData, setQueryData, displayed, close } = props;

  function onSubmit(
    newQueryData: IQueryBuilderModel,
    formikHelpers: FormikHelpers<IQueryBuilderModel>,
  ) {
    const allMetricNames = new Set<string>();
    let hasError = false;
    // Validate that all metric names are unique.
    for (const metric of newQueryData.metrics) {
      const uniqueMetricName =
        getFullyQualifiedColumnName(metric.column) + "_" + metric.operatorType;

      if (allMetricNames.has(uniqueMetricName)) {
        formikHelpers.setFieldError(
          "metrics[0].operatorType",
          "Duplicate metric, metrics must be unique.",
        );
        hasError = true;
      }
      allMetricNames.add(uniqueMetricName);
    }

    if (hasError) {
      return Promise.resolve();
    }

    //generating alias for metric column
    let columnNameIncrement = 1;
    const allColumnNames = new Set<string>();
    const columnToName = new Map<IQueryBuilderMetric, string>();

    // Make sure all column names are unique.
    for (const metric of newQueryData.metrics) {
      const originalName = `${metric.column.column}_${metric.operatorType.replaceAll(" ", "_")}`;
      let name = originalName;
      while (allColumnNames.has(name)) {
        name = `${originalName}${columnNameIncrement}`;
        ++columnNameIncrement;
      }
      columnNameIncrement = 1;

      allColumnNames.add(name);
      columnToName.set(metric, name);
    }

    const newMetrics: IQueryBuilderMetric[] = [];
    for (const metric of newQueryData.metrics) {
      const aliasName = columnToName.get(metric) ?? "";
      const newMetricCol = { ...metric.column, alias: aliasName };
      newMetrics.push({ ...metric, column: newMetricCol });
    }

    setQueryData({ ...newQueryData, metrics: newMetrics });
    close();
    // This is a promise to make Formik happy.
    // Without this they think the form is always submitting and immediately show
    // validation errors when adding new metrics.
    return Promise.resolve();
  }

  // Destroy the modal if the modal is closed.
  // Otherwise we run queries when the modal is closed.
  if (!displayed) {
    return null;
  }

  const yupSchema = Yup.object<YupOptionalSchema<IQueryBuilderModel>>({
    metrics: Yup.array().of(
      Yup.object<YupRequiredSchema<IQueryBuilderMetric>>({
        operatorType: Yup.string()
          .nullable()
          .trim()
          .required("A Metric Type is required"),
        column: Yup.object<YupOptionalSchema<IQueryBuilderColumn>>({
          table: Yup.object<YupOptionalSchema<IQueryBuilderTable>>({
            tableName: Yup.string()
              .nullable()
              .trim()
              .required("A Table Name is required."),
          }),
          column: Yup.string()
            .nullable()
            .trim()
            .required("A Column Name is required."),
        }),
      }),
    ),
  });

  let defaultFormValues = queryData;
  if (queryData.metrics.length === 0) {
    defaultFormValues = produce(queryData, (draft) => {
      draft.metrics.push({
        column: {
          column: "",
          dataType: DataType.VARCHAR,
          table: {
            connectionId: "",
            connectionName: "",
            driver: "",
            schema: "",
            tableName: "",
            tableAlias: "",
            tableType: "",
          },
        },
        operatorType: QueryAggregateFunctionType.COUNT,
      });
    });
  }

  return (
    <Formik
      initialValues={defaultFormValues}
      validationSchema={yupSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit }) => (
        <CDataModalV2
          displayed={displayed}
          close={close}
          title="Add Metrics"
          className="addMetricsModal"
          primaryButton={
            <CDataButton
              buttonType={ButtonType.Primary}
              disabled={values.metrics.length === 0}
              onClick={() => handleSubmit()}
            >
              Confirm
            </CDataButton>
          }
        >
          <AddMetricsFormBody />
        </CDataModalV2>
      )}
    </Formik>
  );
}

function AddMetricsFormBody() {
  const { values, setValues, getFieldMeta } =
    useFormikContext<IQueryBuilderModel>();

  function onAddNewMetric() {
    setValues(
      produce(values, (draft) => {
        draft.metrics.push({
          column: {
            column: "",
            dataType: DataType.VARCHAR,
            table: {
              connectionId: "",
              connectionName: "",
              driver: "",
              schema: "",
              tableName: "",
              tableAlias: "",
              tableType: "",
            },
          },
          operatorType: QueryAggregateFunctionType.COUNT,
        });
      }),
    );
  }

  function onRemoveMetric(metricIndex: number) {
    setValues(
      produce(values, (draft) => {
        draft.metrics.splice(metricIndex, 1);
      }),
      true,
    );
  }

  return (
    <div className="addMetricsModal-body">
      <div>
        Select the columns and the mathematical function you want to use when
        aggregating the results.
      </div>

      <div>
        <div className="headers">
          <div className="header-text fw-bold required">Table Name</div>
          <div className="header-text fw-bold required">Column Name</div>
          <div className="header-text fw-bold required">Metric Type</div>
          {/* Empty for the trash icon. */}
          <div className="trashIconContainer"></div>
        </div>

        <div className="table-input-container">
          {values.metrics.map((metric, index) => {
            const tableMeta = getFieldMeta(
              `metrics[${index}].column.tableName`,
            );

            const columnMeta = getFieldMeta(`metrics[${index}].column.column`);

            const metricTypeMeta = getFieldMeta(
              `metrics[${index}].column.operatorType`,
            );

            return (
              <Row key={index} className="input-row">
                <Col className="input-col">
                  <div className="input-container border-shadow">
                    <TableSelect metricIndex={index} />
                  </div>
                  {tableMeta.touched && tableMeta.error && (
                    <CDataTypography
                      variant="typography-variant-helper-text"
                      color="typography-color-danger"
                    >
                      {tableMeta.error}
                    </CDataTypography>
                  )}
                </Col>
                <Col className="input-col">
                  <div className="input-container border-shadow">
                    <MetricColumnSelect metricIndex={index} />
                  </div>
                  {columnMeta.touched && columnMeta.error && (
                    <CDataTypography
                      variant="typography-variant-helper-text"
                      color="typography-color-danger"
                    >
                      {columnMeta.error}
                    </CDataTypography>
                  )}
                </Col>
                <Col className="input-col">
                  <div className="input-container border-shadow">
                    <MetricTypeSelect metricIndex={index} />
                  </div>
                  {metricTypeMeta.touched && metricTypeMeta.error && (
                    <CDataTypography
                      variant="typography-variant-helper-text"
                      color="typography-color-danger"
                    >
                      {metricTypeMeta.error}
                    </CDataTypography>
                  )}
                </Col>
                <div className="trashIconContainer">
                  <CDataButton
                    buttonType={ButtonType.Borderless}
                    aria-label="Delete"
                    onClick={() => onRemoveMetric(index)}
                  >
                    <i className="fa fa-solid fa-trash-can" />
                  </CDataButton>
                </div>
              </Row>
            );
          })}
        </div>
      </div>

      <div>
        <CDataButton
          buttonType={ButtonType.PrimaryOutline}
          onClick={onAddNewMetric}
        >
          <i className="fa fa-solid fa-plus me-1" /> Add Metric
        </CDataButton>
      </div>
    </div>
  );
}

type TableSelectProps = {
  metricIndex: number;
};

function TableSelect(props: TableSelectProps) {
  const { metricIndex } = props;

  const { values, setValues } = useFormikContext<IQueryBuilderModel>();

  const metric = values.metrics[metricIndex];

  const allTables = [values.from, ...values.joins.map((j) => j.right.table)];

  const selectedTable =
    metric.column.table.tableName != null
      ? allTables.find(
          (table) => table.tableAlias === metric.column.table.tableAlias,
        )!
      : null;

  function onChangeTable(newTable: IQueryBuilderTable) {
    setValues(
      produce(values, (draft) => {
        const metric = draft.metrics[metricIndex];
        metric.column.table = newTable;
      }),
      true,
    );
  }

  return (
    <CDataAutocomplete
      options={allTables}
      menuClass="query-builder-table-dropdown-menu"
      dropdownLabel={(option) => {
        return <QueryBuilderTableDropdownOption option={option} />;
      }}
      getOptionLabel={(option) => option.tableAlias}
      isOptionEqualToValue={(option, value) =>
        option.tableAlias === value.tableAlias
      }
      handleChange={(_event, newTable) => onChangeTable(newTable)}
      selectedValue={selectedTable ?? null}
    />
  );
}
