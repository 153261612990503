import { useState } from "react";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import useQueryTabs from "../Tabs/useQueryTabs";
import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import { ToastrError } from "../../../../services/toastrService";
import {
  CDataAutocomplete,
  CDTableAutoComplete,
} from "@cdatacloud/component-library";
import { QueryBuilderConnectionDropdownOption } from "./tabs/Builder/components/QueryBuilderConnectionDropdownOption";
import { IConnection } from "../../../../models";
import { getTables } from "../../../../api/metadata/GetTables";
import { getSchemas } from "../../../../api/metadata/GetSchemas";
import { Spinner } from "reactstrap";

type DropdownOption = {
  label: string;
  value: any;
  tableType?: string;
};

interface IOpenQueryBuilderModalProps {
  connectionList: IConnection[];
  isModalDisplayed: boolean;
  setIsModalDisplayed: (isModalDisplayed: boolean) => void;
}

export const OpenQueryBuilderModal = (props: IOpenQueryBuilderModalProps) => {
  const queryTabs = useQueryTabs();
  const [selectedConnection, setSelectedConnection] =
    useState<DropdownOption | null>(null);
  const [schemas, setSchemas] = useState<DropdownOption[]>([]);
  const [selectedSchema, setSelectedSchema] = useState<DropdownOption | null>(
    null,
  );
  const [tables, setTables] = useState<DropdownOption[]>([]);
  const [selectedTable, setSelectedTable] = useState<DropdownOption | null>(
    null,
  );
  const [isProcessing, setIsProcessing] = useState(false);

  const connections: DropdownOption[] = props.connectionList.map(
    (connection) => {
      return {
        label: connection.name!,
        value: connection,
      };
    },
  );

  const onConnectionChange = async (newConnection: DropdownOption) => {
    setIsProcessing(true);
    setSelectedConnection(newConnection);
    const data = await getSchemas(newConnection.value.name);

    if ("error" in data) {
      ToastrError("Error retrieving schema list", data.error!.message);
      clearSchemas();
      clearTables();
    } else if ("results" in data) {
      const matchedSchemas = data.results![0].rows;

      const schemaArray = matchedSchemas!.map((el: any) => {
        return {
          label: el[1],
          value: el[1],
        };
      });

      setSchemas(schemaArray);
      if (schemaArray.length === 1) {
        await onSchemaChange(schemaArray[0], newConnection);
      } else {
        setSelectedSchema(null);
        clearTables();
      }
    }

    setIsProcessing(false);
  };

  const onSchemaChange = async (
    newSchema: DropdownOption,
    newConnection?: DropdownOption,
  ) => {
    const connection = newConnection ? newConnection : selectedConnection;
    setIsProcessing(true);
    setSelectedSchema(newSchema);
    const data = await getTables(connection!.value.name, newSchema.value);
    if ("error" in data) {
      ToastrError("Error retrieving schema list", data.error!.message);
      clearTables();
    } else if ("results" in data) {
      const matchedTables = data.results![0].rows;
      const tableArray = matchedTables!.map((table: any) => {
        return {
          label: table[2],
          value: table[2],
          tableType: table[3],
        };
      });

      setTables(tableArray);
      setSelectedTable(null);
    }

    setIsProcessing(false);
  };

  const onTableChange = (newTable: DropdownOption) => {
    setSelectedTable(newTable);
  };

  const clearSchemas = () => {
    setSelectedSchema(null);
    setSchemas([]);
  };

  const clearTables = () => {
    setSelectedTable(null);
    setTables([]);
  };

  const openQueryBuilderTab = () => {
    queryTabs.AddQueryBuilderTab(
      selectedConnection!.value.name,
      selectedConnection!.value.id,
      selectedSchema!.value,
      selectedTable!.value,
      selectedConnection!.value.driver,
    );

    props.setIsModalDisplayed(false);
  };

  const isSchemaFieldDisabled =
    !selectedConnection?.value || schemas?.length <= 1;
  const isTableFieldDisabled = !selectedSchema?.value;
  const isConfirmButtonDisabled =
    !selectedConnection?.value ||
    !selectedSchema?.value ||
    !selectedTable?.value;

  const primaryButton = (
    <CDataButton
      buttonType={ButtonType.Primary}
      onClick={() => openQueryBuilderTab()}
      disabled={isConfirmButtonDisabled}
    >
      Confirm
    </CDataButton>
  );

  const cancelButton = (
    <CDataButton
      buttonType={ButtonType.Secondary}
      onClick={() => props.setIsModalDisplayed(false)}
    >
      Cancel
    </CDataButton>
  );

  return (
    <CDataModalV2
      title={"Query Builder"}
      displayed={props.isModalDisplayed}
      primaryButton={primaryButton}
      secondaryButton={cancelButton}
      spacedFooter={false}
      close={() => props.setIsModalDisplayed(false)}
    >
      <div hidden={!isProcessing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
      Build custom queries without writing code! Simply select a connection and
      a table to get started.
      <div className="my-3">
        <h5 className="required">Connection</h5>
        <CDataAutocomplete
          key={selectedConnection?.value.id}
          id={selectedConnection?.value.id}
          options={connections}
          dropdownLabel={(option) => {
            return (
              <QueryBuilderConnectionDropdownOption option={option.value} />
            );
          }}
          getOptionLabel={(option) =>
            option.value.name ? option.value.name : ""
          }
          handleChange={(_event, newConnection) =>
            onConnectionChange(newConnection)
          }
          isOptionEqualToValue={(option, value) => {
            return option.value.id === value.value.id;
          }}
          selectedValue={selectedConnection}
        />
      </div>
      <div className="my-3">
        <h5 className="required">Schema</h5>
        <CDataAutocomplete
          key={selectedSchema?.value}
          id={selectedSchema?.value}
          options={schemas}
          dropdownLabel={(option) => option.label}
          getOptionLabel={(option) => option.label}
          handleChange={(_event, newSchema) => onSchemaChange(newSchema)}
          disableWholeSelector={isSchemaFieldDisabled}
          selectedValue={selectedSchema}
        />
      </div>
      <div className="mt-3">
        <h5 className="required">Table</h5>
        <CDTableAutoComplete
          key={selectedTable?.value}
          id={selectedTable?.value}
          options={tables}
          getOptionLabel={(option) => option.label}
          dropdownLabel={(option) => option.label}
          handleChange={(_event, newTable) => onTableChange(newTable)}
          disableWholeSelector={isTableFieldDisabled}
          selectedValue={selectedTable}
        />
      </div>
    </CDataModalV2>
  );
};
