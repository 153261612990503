import { CDataAutocomplete, ICDataAutocomplete } from "./CDataAutocomplete";
import classnames from "classnames";
/**
 * Wrapper component of CDataAutoComplete for adding tableType icons in table dropdown options.
 */
export function CDTableAutoComplete<T>(props: ICDataAutocomplete<T>) {
  const tableDropdownLabel = (option: T) => (
    <>
      {option && typeof option === "object" && "tableType" in option && (
        <span style={{ marginRight: "8px" }}>
          <i
            className={classnames({
              "fa-regular fa-table": option.tableType === "TABLE",
              "fa-light fa-border-none": option.tableType === "VIEW",
            })}
          />
        </span>
      )}
      {props.dropdownLabel(option)} {/* Use the existing method */}
    </>
  );

  return <CDataAutocomplete {...props} dropdownLabel={tableDropdownLabel} />;
}
