import { cdataFetchReturnHeaders } from "src/api/cdataFetch";
import { IQueryRequest, IQueryResult } from "src/models";
import { IServiceError } from "src/models/IServiceError";

export async function getTableResultColumns(queryInput: string) {
  const data: IQueryRequest = {
    query: queryInput,
    defaultCatalog: "CData",
    schemaOnly: true,
    timeout: 60,
  };

  return await cdataFetchReturnHeaders<IQueryResult | IServiceError>({
    method: "POST",
    url: "/query",
    body: JSON.stringify(data),
  });
}
