import { useNavigate } from "react-router-dom";
import { getSettingsPageSubscriptionTab } from "./SubscriptionProvider";
import { CDataTypography } from "../components/text/CDataTypography";

export function PremiumConnectorDisabledToolTip() {
  const navigate = useNavigate();

  return (
    <CDataTypography
      color="typography-color-white"
      variant="typography-variant-tooltip"
    >
      Premium Connectors are not available with your subscription.
      <a
        className="upgrade-link"
        onClick={() =>
          navigate("/settings?defaultTab=" + getSettingsPageSubscriptionTab())
        }
      >
        {" "}
        Upgrade
      </a>{" "}
      your plan today to continue using this connection.
    </CDataTypography>
  );
}
