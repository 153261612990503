import React, { useContext } from "react";
import { MSSQL } from "@codemirror/lang-sql";
import { EditorView } from "@codemirror/view";
import { langs } from "@uiw/codemirror-extensions-langs";
import { Prec } from "@codemirror/state";
import useQueryTabs from "../Tabs/useQueryTabs";
import ResizableDiv from "../../../../components/ResizableDiv";
import { QueryTabsContext } from "../Tabs/QueryTabsContext";
import { createDeepCopy } from "../../../../utility/CreateDeepCopy";
import { autocompletion } from "@codemirror/autocomplete";
import { CDataCodeMirror } from "../../../../components/codemirror/CDataCodeMirror";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";
import { IConnection } from "src/models";
import { useSQLAutocompletion } from "./useSQLAutocompletion";

interface IQueryEditor {
  currentQueryInput: string;
  queryEditorDisabled: boolean;
  rowLimitLockdown: boolean;
  setCurrentQueryInput: (input: string) => void;
  setExecuteDisabled: (disabled: boolean) => void;
  hasUnsavedChanges: boolean;
  setUnsavedChanges: () => void;
  queryEditorHeight: number;
  setQueryEditorHeight: (queryEditorHeight: number) => void;
  connectionsList: IConnection[];
}

export const QueryEditor = (props: IQueryEditor) => {
  const tabContext = useContext(QueryTabsContext);
  const { getQueryEditorKeywords } = useSQLAutocompletion(
    props.connectionsList,
  );
  const tabs = useQueryTabs();
  const flags = useFeatureFlags().getFlags(["data_explorer_autocomplete"]);

  const setQueryTabInput = (
    event: React.FocusEvent<HTMLDivElement, Element>,
  ) => {
    tabContext.setTabs((previousTabs) => {
      const newTabs = createDeepCopy(previousTabs);
      const rowToMutate = newTabs.find((tab) => tab.id === tabs.CurrentTabId);
      if (rowToMutate) {
        rowToMutate.queryString = event.target.innerText;
      }

      return newTabs;
    });
  };

  const autocompleteExtensions = [
    langs.sql({
      upperCaseKeywords: true,
      dialect: MSSQL,
    }),
    flags.data_explorer_autocomplete.enabled
      ? autocompletion({ override: [getQueryEditorKeywords] })
      : autocompletion(),
  ];

  const extensions = [
    autocompleteExtensions,
    EditorView.lineWrapping,
    EditorView.updateListener.of((update) => {
      if (update.docChanged) {
        const value = update.state.doc.toString();
        props.setCurrentQueryInput(value);
        tabs.SetTabEmpty(tabs.CurrentTabId, value.trim() === "");
        value.trim() === "" || props.rowLimitLockdown
          ? props.setExecuteDisabled(true)
          : props.setExecuteDisabled(false);
      }
    }),
    EditorView.editable.of(!props.queryEditorDisabled),
    Prec.high(
      EditorView.domEventHandlers({
        keydown: () => {
          if (!props.hasUnsavedChanges) {
            props.setUnsavedChanges();
          }
          return false;
        },
      }),
    ),
  ];

  return (
    <ResizableDiv
      initialHeight={150}
      minHeight={150}
      maxHeight={window.innerHeight * 0.24}
      shouldResizeHorizontally={false}
      shouldResizeVertically={true}
      onHeightChange={props.setQueryEditorHeight}
    >
      <CDataCodeMirror
        sqlText={props.currentQueryInput}
        customExtensions={extensions}
        disableDefaultExtensions
        height={`${props.queryEditorHeight}px`}
        theme="light"
        onBlur={(event) => setQueryTabInput(event)}
        basicSetup={{
          bracketMatching: true,
          autocompletion: true,
        }}
        editable={!props.queryEditorDisabled}
      />
    </ResizableDiv>
  );
};
