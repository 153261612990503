import { useMutation } from "@tanstack/react-query";
import { getTableResultColumns } from "../../api/getTableResultColumns";
import { ToastrError } from "src/services/toastrService";
import { HttpError } from "src/api/cdataFetch";
import { IQueryResult } from "src/models";

async function getTableResultsWithDuplicateColumns(queryInput: string) {
  try {
    const results = await getTableResultColumns(queryInput);

    const columnsSet = new Set<string>();
    if ("error" in results.data) {
      throw new Error(results.data.error?.message ?? "");
    }

    const schema = (results.data as IQueryResult)?.results?.[0]?.schema ?? [];
    const queryId = results.headers.get("x-cdata-queryid");

    const newDuplicateColumns: string[] = [];
    if (schema?.length) {
      for (let i = 0; i < schema?.length; i++) {
        const currColumn = schema[i].columnLabel;
        if (!currColumn) continue;

        if (columnsSet.has(currColumn)) {
          newDuplicateColumns.push(currColumn);
        } else {
          columnsSet.add(currColumn);
        }
      }
    }

    return {
      duplicateColumns: newDuplicateColumns,
      queryId,
      invalidQuery: false,
    };
  } catch (error) {
    let headers: Headers = new Headers();
    if (error instanceof HttpError) {
      headers = error.headers;
    }

    ToastrError(
      "Failed to fetch results due to the following error:",
      error.message,
    );

    return {
      duplicateColumns: [],
      queryId: headers.get("x-cdata-queryid") ?? "",
      invalidQuery: true,
    };
  }
}

export function useDuplicateColumnFinder() {
  const { mutateAsync: getTableResultColumnsAsync } = useMutation({
    mutationKey: ["/query/columns"],
    mutationFn: getTableResultsWithDuplicateColumns,
  });

  return { findDuplicateColumns: getTableResultColumnsAsync };
}
