import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { useAPI } from "../../../../components/useAPI";
import { RequestType } from "../../../../components/withAPI";
import { ToastrError, ToastrSuccess } from "../../../../services/toastrService";
import { useContext, useState } from "react";
import { Spinner } from "reactstrap";
import useQueryTabs from "../Tabs/useQueryTabs";
import { QueryTabsContext } from "../Tabs/QueryTabsContext";
import { createDeepCopy } from "../../../../utility/CreateDeepCopy";
import { useDuplicateColumnFinder } from "./useDuplicateColumnFinder";

export type UpdateDerivedViewModalProps = {
  displayed: boolean;
  close: () => void;
  fetchDerivedViews: () => void;
  derivedViewTitle?: string; // only present if on the edit derived view page
};

export function UpdateDerivedViewModal(props: UpdateDerivedViewModalProps) {
  const api = useAPI();
  const tabs = useQueryTabs();
  const tabContext = useContext(QueryTabsContext);
  const { findDuplicateColumns } = useDuplicateColumnFinder();

  const { displayed, close, fetchDerivedViews, derivedViewTitle } = props;

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const tab = tabs.List.find((t) => t.id === tabs.CurrentTabId);

  if (!tab) {
    return null;
  }

  const updateDerivedView = async () => {
    setIsProcessing(true);

    const trimmedQueryInput = tab.queryString.trim();
    const values = {
      Query: trimmedQueryInput,
    };

    //verify duplicate column names before actually saving the dervied view.
    const { duplicateColumns, queryId, invalidQuery } =
      await findDuplicateColumns(trimmedQueryInput);

    if (invalidQuery) {
      //if '/query' call throws error, then don't proceed to save the derived view.
      close();
      return;
    }

    if (duplicateColumns?.length > 0) {
      const duplicateColumnNames = duplicateColumns.join(", ");
      ToastrError(
        "Duplicate column names detected",
        `Unable to save derived view due to an unresolved SQL compilation error. Ambiguous column names: ${duplicateColumnNames}.`,
        `Query ID: ${queryId}`,
      );
      close();
      return;
    }

    const { status } = await api.callAPI(
      RequestType.Put,
      `/account/derivedViews/${encodeURIComponent(tab.uniqueId ?? "")}`,
      "Failed to update derived view due to the following error:",
      values,
    );

    if (status === 200) {
      ToastrSuccess(
        "Derived View Successfully Saved!",
        `${tab.tabName} was successfully saved.`,
      );
      await fetchDerivedViews();

      // Update original tab if we're editing an existing derived view
      const currentTab = tabs.List.find((t) => t.id === tab.id);
      if (currentTab?.parentTabId) {
        const originalTab = tabs.List.find(
          (tab) => tab.id === currentTab?.parentTabId,
        );
        tabContext.setTabs((previousTabs) => {
          const newTabs = createDeepCopy(previousTabs);
          const rowToMutate = newTabs.find((t) => t.id === originalTab?.id);
          if (rowToMutate) {
            rowToMutate.queryString = currentTab.queryString;
          }

          return newTabs;
        });
      }

      tabs.SetUnsavedChanges(tabs.CurrentTabId, false);
      close();
    }

    setIsProcessing(false);
  };

  const updateEditDerivedView = async () => {
    setIsProcessing(true);
    const trimmedQueryInput = tab.queryString.trim();
    const values = {
      Name: derivedViewTitle, // I don't think we can use tab name here
      Query: trimmedQueryInput,
    };

    //verify duplicate column names before actually saving the dervied view.
    const { duplicateColumns, queryId, invalidQuery } =
      await findDuplicateColumns(trimmedQueryInput);

    if (invalidQuery) {
      //if '/query' call throws error, then don't proceed to save the derived view.
      close();
      return;
    }

    if (duplicateColumns?.length > 0) {
      const duplicateColumnNames = duplicateColumns.join(", ");
      ToastrError(
        "Duplicate column names detected",
        `Unable to save derived view due to an unresolved SQL compilation error. Ambiguous column names: ${duplicateColumnNames}.`,
        `Query ID: ${queryId}`,
      );
      close();
      return;
    }

    const { status } = await api.callAPI(
      RequestType.Put,
      `/account/derivedViews/${encodeURIComponent(tab.uniqueId ?? "")}`,
      "Failed to update derived view due to the following error:",
      values,
    );

    if (status === 200) {
      ToastrSuccess(
        "Derived View Successfully Saved!",
        `${derivedViewTitle} was successfully saved.`,
      );

      await fetchDerivedViews();

      // Update the tab title on save
      tabContext.setTabs((previousTabs) => {
        const newTabs = createDeepCopy(previousTabs);
        const rowToMutate = newTabs.find((tab) => tab.id === tabs.CurrentTabId);
        if (rowToMutate) {
          rowToMutate.tabName = derivedViewTitle!;
          rowToMutate.tableName = derivedViewTitle;
          rowToMutate.unsavedChanges = true;
        }

        return newTabs;
      });

      close();
    }

    setIsProcessing(false);
  };

  return (
    <>
      <CDataModalV2
        modalSize="lg"
        displayed={displayed}
        close={close}
        title="Save Changes"
        spacedFooter={false}
        primaryButton={
          <CDataButton
            buttonType={ButtonType.Primary}
            onClick={
              derivedViewTitle ? updateDerivedView : updateEditDerivedView
            }
          >
            Save
          </CDataButton>
        }
        secondaryButton={
          <CDataButton
            buttonType={ButtonType.Secondary}
            onClick={close}
            data-testid="button-cancel"
          >
            Cancel
          </CDataButton>
        }
      >
        You are about to overwrite {tab.tabName} with new changes. Are you sure
        you want to proceed?
      </CDataModalV2>
      <div hidden={!isProcessing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </>
  );
}
