import {
  CompletionContext,
  CompletionResult,
  Completion,
  pickedCompletion,
} from "@codemirror/autocomplete";
import { EditorView } from "@uiw/react-codemirror";
import { compareStrings } from "src/utility/CompareStrings";
import { MSSQL } from "@codemirror/lang-sql";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { getSchemasForConnection } from "src/api/metadata/getSchemasForConnection";
import { getTablesForSchema } from "src/api/metadata/getTablesForSchema";
import { IConnection } from "src/models";
import { getColumnsForTable } from "src/api/metadata/getColumnsForTable";

type SchemaOption = {
  connection: string;
  schema: string;
};

type TableOption = {
  connection: string;
  schema: string;
  table: string;
};

export const useSQLAutocompletion = (connectionsList: IConnection[]) => {
  const [queriedConnections, setQueriedConnections] = useState<string[]>([]);
  const [queriedSchemas, setQueriedSchemas] = useState<SchemaOption[]>([]);
  const [queriedTables, setQueriedTables] = useState<TableOption[]>([]);

  const [schemaOptions, setSchemaOptions] = useState<SchemaOption[]>([]);
  const [tableOptions, setTableOptions] = useState<TableOption[]>([]);
  const [columnOptions, setColumnOptions] = useState<string[]>([]);

  const { mutate: updateSchemaList } = useMutation({
    mutationKey: ["/schemas"],
    mutationFn: getSchemasForConnection,
    onSuccess: (schemaList) => {
      // Add new schemas to the existing schema list
      setSchemaOptions([
        ...schemaOptions,
        ...schemaList.map((s) => ({ connection: s.catalog, schema: s.schema })),
      ]);
    },
  });

  const { mutate: updateTableList } = useMutation({
    mutationKey: ["/tables"],
    mutationFn: getTablesForSchema,
    onSuccess: (tableList) => {
      // Add new tables to the existing table list
      setTableOptions([
        ...tableOptions,
        ...tableList.map((t) => ({
          connection: t.catalog,
          schema: t.schema,
          table: t.tableName,
        })),
      ]);
    },
  });

  const { mutate: updateColumnList } = useMutation({
    mutationKey: ["/columns"],
    mutationFn: getColumnsForTable,
    onSuccess: (columnList) => {
      // Add new columns to the existing column list
      setColumnOptions([
        ...columnOptions,
        ...columnList.map((c) => c.columnName),
      ]);
    },
  });

  const fetchSchemasForConnection = (connectionName: string) => {
    // If  we've already fetched the schemas for the matched connection, stop here.
    if (queriedConnections.some((c) => compareStrings(c, connectionName))) {
      return;
    }

    // Otherwise, add the connection to the list of fetched connections and get its schemas
    setQueriedConnections([...queriedConnections, connectionName]);
    updateSchemaList(connectionName);
  };

  const fetchTablesForSchema = (schemaMatch: SchemaOption) => {
    if (findMatchingSchema(schemaMatch, queriedSchemas)) {
      return;
    }

    setQueriedSchemas([...queriedSchemas, schemaMatch]);
    updateTableList({
      connectionName: schemaMatch.connection,
      schema: schemaMatch.schema,
    });
  };

  const fetchColumnsForTable = (tableMatch: TableOption) => {
    if (findMatchingTable(tableMatch, queriedTables)) {
      return;
    }

    setQueriedTables([...queriedTables, tableMatch]);
    updateColumnList({
      connectionName: tableMatch.connection,
      schema: tableMatch.schema,
      tableName: tableMatch.table,
    });
  };

  const isValidConnection = (connectionName: string) => {
    return connectionsList.some(
      (conn) => conn.name?.toLowerCase() === connectionName.toLowerCase(),
    );
  };

  /** Returns the provided schema in the provided schemaList if it exists */
  const findMatchingSchema = (
    schema: SchemaOption,
    schemaList: SchemaOption[],
  ) => {
    return schemaList.find(
      (s) =>
        compareStrings(s.connection, schema.connection) &&
        compareStrings(s.schema, schema.schema),
    );
  };

  /** Returns the provided table in the provided tableList if it exists */
  const findMatchingTable = (table: TableOption, tableList: TableOption[]) => {
    return tableList.find(
      (t) =>
        compareStrings(t.connection, table.connection) &&
        compareStrings(t.schema, table.schema) &&
        compareStrings(t.table, table.table),
    );
  };

  /** Returns a list of connection names and SQL terms as autocompletion suggestions */
  const getConnectionAndSQLSuggestions = (
    startPos: number,
    firstWord: string,
    bracketsAlreadyPresent: boolean,
  ): CompletionResult => {
    const msSqlKeywords = MSSQL.spec.keywords
      ?.split(" ")
      .map((keyword) => keyword.toUpperCase());

    if (isValidConnection(firstWord)) {
      fetchSchemasForConnection(firstWord);
    }

    return {
      from: startPos,
      options: [
        ...(msSqlKeywords ?? []).map((keyword) => ({
          label: keyword,
          type: "keyword",
          boost: 1,
        })),
        ...(connectionsList ?? []).map((conn) => ({
          label: conn.name ?? "",
          type: "connection",
          boost: 2,
          // This custom function is called if the user selects a suggested connection name
          apply: (
            view: EditorView,
            completion: Completion,
            from: number,
            to: number,
          ) => {
            fetchSchemasForConnection(completion.label);
            const insertText = bracketsAlreadyPresent
              ? completion.label
              : `[${completion.label}]`;
            // Ensures the cursor moves to the right spot if there are existing brackets
            const endingCursorPos =
              from + insertText.length + (bracketsAlreadyPresent ? 1 : 0);

            view.dispatch({
              // Insert the selected item into the editor
              changes: { from, to, insert: insertText },
              // Move the cursor to the end of the selected word
              selection: { anchor: endingCursorPos },
              // Tell the autocompletion that we've made a selection to maintain editor state.
              annotations: [pickedCompletion.of(completion)],
            });
            return true;
          },
        })),
      ],
    };
  };

  /** Returns a list of schemas associated with the preceding connection as autocompletion suggestions */
  const getSchemaSuggestions = (
    startPos: number,
    firstWord: string,
    secondWord: string,
    bracketsAlreadyPresent: boolean,
  ): CompletionResult => {
    const searchSchema: SchemaOption = {
      connection: firstWord,
      schema: secondWord,
    };
    const schemaMatch = findMatchingSchema(searchSchema, schemaOptions);
    if (schemaMatch) {
      fetchTablesForSchema(schemaMatch);
    }

    return {
      from: startPos,
      options: [
        ...(schemaOptions ?? [])
          .filter((s) => compareStrings(s.connection, firstWord))
          .map((schema) => ({
            label: schema.schema,
            type: "schema",
            boost: 3,
            apply: (
              view: EditorView,
              completion: Completion,
              from: number,
              to: number,
            ) => {
              fetchTablesForSchema({
                connection: firstWord,
                schema: completion.label,
              });
              const insertText = bracketsAlreadyPresent
                ? completion.label
                : `[${completion.label}]`;
              const endingCursorPos =
                from + insertText.length + (bracketsAlreadyPresent ? 1 : 0);

              view.dispatch({
                changes: { from, to, insert: insertText },
                selection: { anchor: endingCursorPos },
                annotations: [pickedCompletion.of(completion)],
              });
              return true;
            },
          })),
      ],
    };
  };

  /** Returns a list of tables associated with the preceding schema as autocompletion suggestions */
  const getTableSuggestions = (
    startPos: number,
    firstWord: string,
    secondWord: string,
    bracketsAlreadyPresent: boolean,
  ): CompletionResult => {
    return {
      from: startPos,
      options: [
        ...(tableOptions ?? [])
          .filter(
            (t) =>
              compareStrings(t.connection, firstWord) &&
              compareStrings(t.schema, secondWord),
          )
          .map((table) => ({
            label: table.table,
            type: "table",
            boost: 4,
            apply: (
              view: EditorView,
              completion: Completion,
              from: number,
              to: number,
            ) => {
              fetchColumnsForTable({
                connection: firstWord,
                schema: secondWord,
                table: completion.label,
              });
              const insertText = bracketsAlreadyPresent
                ? completion.label
                : `[${completion.label}]`;
              const endingCursorPos =
                from + insertText.length + (bracketsAlreadyPresent ? 1 : 0);

              view.dispatch({
                changes: { from, to, insert: insertText },
                selection: { anchor: endingCursorPos },
                annotations: [pickedCompletion.of(completion)],
              });
              return true;
            },
          })),
      ],
    };
  };

  /** Returns a list of columns associated with any tables loaded into memory as autocompletion suggestions after the word SELECT */
  const getColumnNames = (
    context: CompletionContext,
    wholeSelectPhraseRegex: RegExp,
  ): CompletionResult | null => {
    // As a sanity check, make sure the text matches the pattern here
    const match = context
      .matchBefore(wholeSelectPhraseRegex)
      ?.text.match(wholeSelectPhraseRegex);

    if (!match) return null;

    // Break the whole selection phrase down into pieces for processing
    const [fullText, selectWord, lastKeyword, inProgressKeyword] = match;

    // If there is nothing being typed, exit now to avoid exceptions
    if (!inProgressKeyword) return null;

    // Set the starting position for the suggestions based on the previous content. If we have no columns yet,
    // start the suggestions after "SELECT". Otherwise, start them after the last found column.
    let startPos = selectWord.length; // Accounts for any number of spaces following the word SELECT
    if (lastKeyword) {
      startPos = fullText.indexOf(lastKeyword);
      startPos += lastKeyword.length;
    }

    const bracketsAlreadyPresent = inProgressKeyword.startsWith("[");
    if (bracketsAlreadyPresent) startPos++;

    return {
      from: startPos,
      options: [
        ...(columnOptions ?? []).map((col) => ({
          label: col ?? "",
          type: "column",
          boost: 3,
          apply: (
            view: EditorView,
            completion: Completion,
            from: number,
            to: number,
          ) => {
            const insertText = bracketsAlreadyPresent
              ? completion.label
              : `[${completion.label}]`;
            const endingCursorPos =
              from + insertText.length + (bracketsAlreadyPresent ? 1 : 0);

            view.dispatch({
              changes: { from, to, insert: insertText },
              selection: { anchor: endingCursorPos },
              annotations: [pickedCompletion.of(completion)],
            });
            return true;
          },
        })),
      ],
    };
  };

  const getFullyQualifiedTableKeywords = (context: CompletionContext) => {
    // Match a sequence of up to three items wrapped in brackets separated by dots, e.g. [Conn1].[Schema].[Table Name]
    // The brackets themselves are included as part of the items for the purpose of parsing them out.
    const stringPattern =
      /(\[?[\w-]+\]?)(?:\.)?(\[?[\w-]+\]?)?(?:\.)?(\[?[\w-]+\]?)?$/;

    const match = context.matchBefore(stringPattern);
    if (!match) return null;

    // Break the regex match down into individual words for processing
    const [fullText, firstWord, secondWord, thirdWord] =
      match.text.match(stringPattern) || [];

    // The starting character position to start match searches from. We have to set this manually for each part of the sequence.
    let startPos = 0;

    const firstWordHasBrackets = firstWord?.startsWith("[");
    const secondWordHasBrackets = secondWord?.startsWith("[");
    const thirdWordHasBrackets = thirdWord?.startsWith("[");

    // Words with brackets stripped to ensure comparisons work
    const cleanFirstWord = firstWord?.replace(/[[\]]/g, "");
    const cleanSecondWord = secondWord?.replace(/[[\]]/g, "");

    const isFirstWordCompleted = fullText?.includes(`[${cleanFirstWord}].`);
    const isSecondWordCompleted = fullText?.includes(`[${cleanSecondWord}].`);

    if (!isFirstWordCompleted) {
      startPos = firstWordHasBrackets ? match.from + 1 : match.from;
      return getConnectionAndSQLSuggestions(
        startPos,
        cleanFirstWord,
        firstWordHasBrackets,
      );
    }

    if (isFirstWordCompleted && !isSecondWordCompleted) {
      const posSkip = secondWordHasBrackets ? 2 : 1;
      startPos = match.from + fullText!.lastIndexOf(".") + posSkip;
      return getSchemaSuggestions(
        startPos,
        cleanFirstWord,
        cleanSecondWord,
        secondWordHasBrackets,
      );
    }

    if (isFirstWordCompleted && isSecondWordCompleted) {
      const posSkip = thirdWordHasBrackets ? 2 : 1;
      startPos = match.from + fullText!.lastIndexOf(".") + posSkip;
      return getTableSuggestions(
        startPos,
        cleanFirstWord,
        cleanSecondWord,
        thirdWordHasBrackets,
      );
    }

    return null;
  };

  const getQueryEditorKeywords = (
    context: CompletionContext,
  ): CompletionResult | null => {
    // Checks if the previous word was SELECT, or if we're in a chain of column names separated by commas after SELECT.
    // If either is true, look at the column names for suggestions. Otherwise, look for sql/connection/schema/table options.
    const columnSelectionPattern = new RegExp(
      /(select\s+)(\[?[\w-]+\]?\s*,\s*)*(\[?[\w-]+\]?\s*)?$/,
      "i", // Tells the regex to ignore casing on SELECT
    );
    const inColumnSelection = context.matchBefore(columnSelectionPattern);

    if (inColumnSelection) {
      return getColumnNames(context, columnSelectionPattern);
    } else {
      return getFullyQualifiedTableKeywords(context);
    }
  };

  return { getQueryEditorKeywords };
};
