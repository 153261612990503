import { Button, Col, Label, Row, UncontrolledTooltip } from "reactstrap";
import { LimitControl } from "./LimitControl";
import { QueryEditorSaveButton } from "./QueryEditorSaveButton";
import { useState } from "react";
import { QueryTabType } from "../Tabs/queryTabType";
import useQueryTabs, { IQueryTab } from "../Tabs/useQueryTabs";
import { BillingStatus, UserRole } from "../../../../models";
import { useAppSelector } from "../../../../redux/hooks";

interface IQueryEditorButtonBar {
  tab: IQueryTab;
  abortRunningQueryRequest: () => void;
  cancelButtonDisabled: boolean;
  queryInput: string;
  handleRunClick: React.MouseEventHandler<HTMLButtonElement>;
  handleSaveClick: React.MouseEventHandler<HTMLButtonElement>;
  handleRefreshClickCallback: React.MouseEventHandler<HTMLButtonElement>;
  queryLimit: number;
  setQueryLimit: (queryLimit: number) => void;
  wasQuerySuccessful: boolean;
  executeDisabled: boolean;
  setExecuteDisabled: (newExecuteDisabled: boolean) => void;
  areDerivedViewsAllowed: boolean;
  openSaveChangesModal: () => void;
  openCreateDerivedViewModal: () => void;
  openCreateSavedQueryModal: () => void;
}

export const QueryEditorButtonBar = (props: IQueryEditorButtonBar) => {
  const {
    tab,
    queryInput,
    handleRunClick,
    abortRunningQueryRequest,
    handleSaveClick,
    handleRefreshClickCallback,
    queryLimit,
    setQueryLimit,
    wasQuerySuccessful,
    executeDisabled,
    areDerivedViewsAllowed,
    openSaveChangesModal,
    openCreateDerivedViewModal,
    openCreateSavedQueryModal,
  } = props;

  const usage = useAppSelector((state) => state.usage);
  const user = useAppSelector((state) => state.user);
  const tabs = useQueryTabs();

  const [createDropdownOpen, setCreateDropdownOpen] = useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  //Dropdown needs more time to close in order for tooltip routing not to break.
  const toggleCreateDropdown = () => {
    setTimeout(() => {
      setCreateDropdownOpen(!createDropdownOpen);
    }, 100);
  };

  const isQueryUser = user.role === UserRole.Query;

  const trialOverage =
    usage.rowsOverLimit === true &&
    usage.billingStatus ===
      (BillingStatus.NewAccount ||
        BillingStatus.Trial ||
        BillingStatus.TrialEnding);

  const executeButtonTooltip = trialOverage
    ? "You cannot execute queries because you have reached your rows limit."
    : "After selecting a connection, select Query on a table, column, or view in order to execute the query.";

  const currentTab = tabs.List.find((tab) => tab.id === tabs.CurrentTabId);
  const isSaveChangesVisible =
    tab.tabType === QueryTabType.DerivedView ||
    tab.tabType === QueryTabType.SavedQuery ||
    tab.tabType === QueryTabType.ScheduledQuery
      ? currentTab?.unsavedChanges
      : false;

  return (
    <Row className="data-explorer-top-row align-items-center mx-0">
      <Col className="ps-0">
        <span id="executeQueryButton">
          <Button
            color="primary"
            data-testid="execute-button"
            onClick={handleRunClick}
            disabled={executeDisabled}
            className={executeDisabled ? "me-2 no-pointer-event" : "me-2"}
          >
            <i className="fa fa-solid fa-play me-2" />
            Execute
          </Button>
        </span>
        <UncontrolledTooltip
          placement="top"
          target="executeQueryButton"
          trigger="hover"
          className={executeDisabled ? "" : "d-none"}
        >
          {executeButtonTooltip}
        </UncontrolledTooltip>
        <Button
          color="secondary"
          aria-label="cancel-button"
          onClick={() => abortRunningQueryRequest()}
          disabled={props.cancelButtonDisabled}
        >
          Cancel
        </Button>
        <Button
          disabled={queryInput === ""}
          className="btn-outline-secondary btn-custom-no-background clear-button"
          color=""
          onClick={(e: any) => handleRefreshClickCallback(e)}
          data-testid="button-clear"
        >
          <i className="fa-regular fa-xmark align-middle me-2"></i>
          <span className="align-middle">Clear</span>
        </Button>
      </Col>
      <Col>
        <div className="float-end limit-container">
          <Label className="me-2">Limit:</Label>
          <LimitControl queryLimit={queryLimit} setQueryLimit={setQueryLimit} />
          <QueryEditorSaveButton
            areDerivedViewsAllowed={areDerivedViewsAllowed}
            connectionsOverLimit={usage.connectionsOverLimit!}
            createDropdownOpen={createDropdownOpen}
            handleSaveClick={handleSaveClick}
            isQueryUser={isQueryUser}
            openSaveChangesModal={openSaveChangesModal}
            isSaveChangesVisible={isSaveChangesVisible!}
            areSaveAsButtonsDisabled={executeDisabled || !wasQuerySuccessful}
            openCreateDerivedViewModal={openCreateDerivedViewModal}
            openCreateSavedQueryModal={openCreateSavedQueryModal}
            toggle={() => setTooltipOpen(!tooltipOpen)}
            toggleCreateDropdown={toggleCreateDropdown}
            tooltipOpen={tooltipOpen}
            usersOverLimit={usage.usersOverLimit!}
            queryText={queryInput}
          />
        </div>
      </Col>
    </Row>
  );
};
