import { Col, Label, Row, UncontrolledTooltip } from "reactstrap";
import { TextFieldProps } from "@mui/material";
import {
  CDataAutocomplete,
  CDTableAutoComplete,
} from "@cdatacloud/component-library";

type IDataLabelAndDropdown<T> = {
  label: string;
  isDisabled: boolean;
  optionLabel: (option: T) => string;
  dropdownLabel: (option: T) => JSX.Element | string;
  menuClass?: string;
  id?: string;
  handleChange: (option: T) => void;
  helperText?: JSX.Element;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  options: T[];
  renderInput?: (params: Partial<TextFieldProps>) => React.ReactNode;
  tooltipId?: string;
  tooltipText?: JSX.Element;
  validationError?: string;
  value: T | null;
  addTableTypeIcon?: boolean;
};

function DataLabelAndDropdown<T>(props: IDataLabelAndDropdown<T>) {
  const {
    isDisabled,
    label,
    optionLabel,
    dropdownLabel,
    menuClass,
    id,
    handleChange,
    helperText,
    isOptionEqualToValue,
    options,
    renderInput,
    tooltipId,
    tooltipText,
    validationError,
    value,
    addTableTypeIcon,
  } = props;

  return (
    <Row className="data-label-and-dropdown">
      <Col className="mb-3">
        <Label for={id} className="dropdown-label required mb-2">
          {label}
        </Label>
        <div id={`selection-field-${tooltipId}`}>
          {addTableTypeIcon ? (
            <CDTableAutoComplete<T>
              disableWholeSelector={isDisabled}
              dropdownLabel={dropdownLabel}
              getOptionLabel={optionLabel}
              handleChange={(_event, newValue) => handleChange(newValue)}
              id={id}
              isInvalid={Boolean(validationError)}
              isOptionEqualToValue={isOptionEqualToValue}
              menuClass={menuClass}
              options={options}
              renderInput={renderInput}
              selectedValue={value ?? null}
            />
          ) : (
            <CDataAutocomplete
              disableWholeSelector={isDisabled}
              dropdownLabel={dropdownLabel}
              getOptionLabel={optionLabel}
              handleChange={(_event, newValue) => handleChange(newValue)}
              id={id}
              isInvalid={Boolean(validationError)}
              isOptionEqualToValue={isOptionEqualToValue}
              menuClass={menuClass}
              options={options}
              renderInput={renderInput}
              selectedValue={value ?? null}
            />
          )}
        </div>
        {tooltipText && tooltipId ? (
          <UncontrolledTooltip
            fade={false}
            autohide={false}
            target={`selection-field-${tooltipId}`}
          >
            {tooltipText}
          </UncontrolledTooltip>
        ) : null}
        {validationError ? (
          <span className="error-text mt-1">{validationError}</span>
        ) : (
          helperText
        )}
      </Col>
    </Row>
  );
}

export default DataLabelAndDropdown;
